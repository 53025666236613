<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <searchToChargeback
          compType="table"
          :detailPageQuery="false"
          @search="search"
          @reset="search"
        />
        <tableComp
          v-loading="loading"
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="goodInfo" label="类目/品牌/型号">
            <template v-slot="scope">
              <span>
                {{ scope.row.categoryName || '-' }} /
                {{ scope.row.brandName || '-' }} /
                {{ scope.row.modelName || '-' }}
              </span>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
  </div>
</template>

<script>
import searchToChargeback from '@/views/data-center/components/searchToChargeback'
import { chargebackTable } from '@/api'
import { formatDate, diffDate } from '@/utils/common'
export default {
  components: { searchToChargeback },
  data () {
    return {
      loading: false,
      detailShow: false,
      orderCode: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      orderType: 1,
      timeType: 3,
      commodityName: '',
      startTime: '',
      endTime: '',
      theadName: [
        { prop: 'reasonContent', label: '原因' },
        { prop: 'customerName', label: '客户' },
        { prop: 'orderCode', label: '订单号' },
        { prop: 'storeName', label: '商家' },
        { prop: 'commodityName', label: '商品名称' },
        { slot: 'goodInfo' },
        { prop: 'rentStartDate', label: '起租日期' },
        { prop: 'repairAmount', label: '起租数量(台)' },
        { prop: 'chargebackDate', label: '退租日期' },
        { prop: 'chargebackQuantity', label: '退租数量(台)' },
        { prop: 'leaseDurationStr', label: '使用时长(天)' },
        { prop: 'repairAmount', label: '报修设备数量' },
        { prop: 'repairPoorAmount', label: '开箱不良数量' }
      ]
    }
  },
  mounted () {
    this.endTime = formatDate(new Date()).slice(0, 7)
    this.startTime = diffDate(-5, 'months', true).slice(0, 7)
    this.getList()
  },
  computed: {},
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        level: this.level,
        cityId: this.cityId,
        timeType: this.timeType,
        storeId: this.storeId,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        start: this.startTime,
        end: this.endTime
      }
      this.loading = true
      chargebackTable(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    search (val) {
      this.curPage = 1
      const {
        timeType,
        startTime,
        endTime,
        level,
        cityId,
        storeId,
        type,
        durationType,
        chargebackCategory,
        chargebackType,
        reason
      } = val
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.type = type
      this.level = level
      this.cityId = cityId
      this.storeId = storeId
      this.durationType = durationType
      this.chargebackCategory = chargebackCategory
      this.chargebackType = chargebackType
      this.reason = reason
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
